import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import { PageSeo } from "../components/PageSeo"

const LeadershipTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const { name, blurb, banner } = markup.frontmatter

  return (
    <Layout>
      <section className="LeadershipTemplate">
        <Banner data={banner} />
        <h2 className="section_heading text-center py-3 py-lg-5">{name}</h2>
        <Member blurb={blurb} />
        <MemberDescription blurb={blurb} />
      </section>
    </Layout>
  )
}

const Member = ({ blurb }) => {
  return (
    <div className="container border-bottom">
      <div className="row justify-content-center">
        {blurb.length &&
          blurb.map((item, index) => (
            <a
              href={`#${item.name}`}
              className="col-12 col-md-6 col-lg-3 text-decoration-none"
              key={`member_${index}`}
            >
              <div className="card border-0 mb-lg-5">
                <div className="card-img text-center">
                  <GatsbyImage
                    image={item.image.childImageSharp.gatsbyImageData}
                    alt={item.name}
                    style={{ borderRadius: "100%", border: "solid 3px #eee" }}
                  />
                </div>
                <div className="card-body px-0 text-center">
                  <h5 className="card-title text-uppercase  text-primary">
                    {item.name}
                  </h5>
                  <p className="card-text text-muted">{item.position}</p>
                </div>
              </div>
            </a>
          ))}
      </div>
    </div>
  )
}

const MemberDescription = ({ blurb }) => {
  return (
    <div className="container">
      <h2 className="section_heading text-center py-3 py-lg-5 mt-4">
        See what Credence Leaders are all about
      </h2>
      <div className="row">
        {blurb.length &&
          blurb.map((item, index) => (
            <div
              key={`memberdescription_${index}`}
              id={`${item.name}`}
              style={{ scrollMargin: "100px" }}
            >
              <div className="card  border-0 mb-4">
                <div className="row g-0">
                  <div className="col-12 text-center col-md-2">
                    <GatsbyImage
                      image={item.image.childImageSharp.gatsbyImageData}
                      alt={item.name}
                      className="desimage m-3"
                      style={{ borderRadius: "100%", border: "solid 3px #eee" }}
                    />
                  </div>
                  <div className="col-12 col-md-10">
                    <div className="card-body">
                      <h5 className="card-title text-uppercase text-primary">
                        {item.name}
                      </h5>
                      <p className="card-text text-muted">{item.position}</p>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
export const LeadershipQuery = graphql`
  query LeadershipQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        blurb {
          name
          position
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                width: 150
                height: 150
                layout: CONSTRAINED
              )
            }
          }
          description
        }
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
export default LeadershipTemplate
export const Head = () => (
  <PageSeo
    title="Credence Leadership Team | INVESTMENT MANAGEMENT SOFTWARE SOLUTION PROVIDER"
    description="Credence Ananlytics partners with various teams to showcase its thought leadership & capabilities"
    keywords="Credence Leadership Team"
  />
)
